.ant-upload-drag-container {
    background-image:url("../../../public/images/dna.svg");
    background-repeat:no-repeat;
    background-size: 60% 60%;
    background-position:center;
}
.ant-upload-drag-container .ant-upload-text {
    padding:10px;
}

.ant-upload.ant-upload-drag {
    border: 3px dashed #d9d9d9 !important;
}

/* .ant-upload.ant-upload-drag {} */